* {
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
  background-color: #f4f7fb;
}

@font-face {
  font-family: "EuropaRegular";
  src: local("Europa-Regular"),
    url("./Assets/Fonts/Europa-Regular.ttf") format("truetype");
  font-weight: 900;
}
@font-face {
  font-family: "EuropaBold";
  src: local("Europa-Bold"),
    url("./Assets/Fonts/Europa-Bold.ttf") format("truetype");
  font-weight: 900;
}
@font-face {
  font-family: "EuropaLight";
  src: local("Europa-Light"),
    url("./Assets/Fonts/Europa-Light.woff2.ttf") format("truetype");
  font-weight: 900;
}
@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* full calendar style */
.fc-theme-standard .fc-popover ::-webkit-scrollbar {
  width: 5px;
}
.fc-theme-standard .fc-popover ::-webkit-scrollbar-thumb {
  background: #3778c2;
  border-radius: 12px;
}

.fc-popover {
  top: 10% !important;
  left: 0 !important;
  right: 0 !important;
  margin: auto !important;
  background-color: #fff;
  color: #333;
  max-height: 400px;
  width: 400px;
  overflow: hidden;
  box-shadow: 2px 5px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  animation: fadeIn 500ms ease-in-out forwards;
}

.fc .fc-popover-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #3778c2;
  color: white;
}
.fc .fc-more-popover .fc-popover-body {
  max-height: calc(400px - 30px);
  overflow: auto;
  padding-bottom: 10px;
  animation: slideIn 500ms ease-in-out forwards;
}
.replay-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: red;
  border-radius: 50%;
}
